button.custom-chip-button {
  font-size: 0.8125rem;
}

.custom-chip,
.custom-chip-button {
  display: inline-flex;
  align-items: center;
  padding: 0 12px;
  height: 32px;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  border-radius: 16px;
  cursor: pointer;
  color: #ffffff;
  background-color: #FF6B6B; /* Same color as primary variant of Chip */
  border: none;
  outline: none;
  transition: background-color 0.2s ease-in-out;
}

.custom-chip-button:hover {
  background-color: #E55A5A; /* Slightly darker on hover */
}

.custom-chip-button .icon,
.custom-chip .icon {
  margin-right: 4px;
}

.custom-chip-button {
  border: none;
  padding: 0 12px;

  cursor: pointer;
}

.custom-chip-button:hover {
  background-color: #FF6B6B;
}

.custom-chip {
  display: inline-flex;
  align-items: center;
  padding: 0 12px;
  height: 32px;
  font-size: 0.875rem;
  background-color: #FF6B6B;
  color: white;
  border-radius: 16px;
}

button.custom-chip-button {
    height: 27px;
}

.custom-chip-button .icon {
    font-size: 17px;
}

.custom-chip-button:disabled {
  background-color: #d3d3d3; 
  color: #a9a9a9;        
  cursor: not-allowed;     
  opacity: 0.6;            
}